import * as Sentry from '@sentry/nextjs'

export function parseJson<T>(value: any): T | null {
  try {
    return JSON.parse(value)
  } catch (err) {
    Sentry.captureException(err)
    return null
  }
}
