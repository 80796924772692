import { getCookie } from 'cookies-next'
import {
  googleAnalitics,
  localStatistic,
  ourQueries,
} from '../constants/constants'
import { parseJson } from './jsonUtils'

export const recordingMetricsForAnalytics = (
  query: any,
  metrics: any,
  newQuery: any,
  setMetrics: (value: any) => void
) => {
  if (typeof window !== 'undefined') {
    const statisticLocalStorage = window.localStorage.getItem(localStatistic)

    let statistic = filteredQueries(
      query,
      statisticLocalStorage && { ...parseJson(statisticLocalStorage) }
    )

    let newStatistic = filteredMetricsWithoutGtm(statistic)

    if (
      JSON.stringify(newStatistic) !== JSON.stringify(metrics) &&
      Object.entries(newQuery).length &&
      Object.keys(newStatistic).length > 1
    ) {
      setMetrics(newStatistic)
    }

    statistic = {
      ...statistic,
      _ga: getCookie(googleAnalitics),
    }

    const jsonNewStatistic = JSON.stringify(statistic)

    if (jsonNewStatistic !== statisticLocalStorage) {
      window.localStorage.setItem(localStatistic, jsonNewStatistic)
    }
  }
}

export const filteredQueries = (queries: any, initialQueries?: any) => {
  let filterOurQuaries = {}

  for (let key in queries) {
    if (!ourQueries.includes(key)) {
      initialQueries
        ? (initialQueries[key] = queries[key])
        : (filterOurQuaries[key] = queries[key])
    }
  }

  return initialQueries ? initialQueries : filterOurQuaries
}

export const filteredMetricsWithoutGtm = (statistic: any) => {
  let newStatistic = {}
  for (let key in statistic) {
    if (key !== '_ga' && key !== 'gtm_debug') {
      newStatistic[key] = statistic[key]
    }
  }

  return newStatistic
}

export const getUTMurlForTripleWhale = (str: string) => {
  return str
    .substring(str.indexOf('?') + 1)
    .split('&')
    .filter((item) => item.includes('utm_'))
    .join('&')
}
