import Client from 'shopify-buy'
export const cashbackPercent = 5

export const clientType = '1'
export const freeShipping = 20

export const shopifyCartCookie = 'shopifyCart'
export const tripleIdCookie = 'triple_id'
export const landingPageCookie = '_landing_page'
export const shopifySapCookie = '_shopify_sa_p'
export const draftAddressCookie = 'draftAddress'
export const customerIdentityCookieName = '_identity'
export const draftOrderIdCookie = 'draftOrderID'
export const discountCodeCookie = 'discountCode'
export const localStatistic = 'statistic'
export const googleAnalitics = '_ga'

export const path = { path: '/' }

export const xHeaderClientType = 'mobile'

export const VITALIVE_SERVER_URL = process.env.NEXT_PUBLIC_SERVER_URL
export const SHOW_BLOG =
  process.env.NEXT_PUBLIC_SHOW_BLOG === 'true' ? true : false

export const memberId = '40184219009156'
export const trialMembershipSku = process.env.NEXT_PUBLIC_TRIAL_MEMBERSHIP_SKU
export const annualmembershipSku = process.env.NEXT_PUBLIC_ANNUAL_MEMBERSHIP_SKU
export const GOOGLE_GEO_KEY = process.env.NEXT_PUBLIC_GOOGLE_GEO_KEY
export const GOOGLE_SHEETS_KEY = process.env.NEXT_PUBLIC_GOOGLE_SHEETS_KEY
export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY

// Shopify
export const shopifyClient = Client.buildClient({
  domain: process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN,
  storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_SECRET,
})

export const promoProductSku =
  process.env.NEXT_PUBLIC_TY_PAGE_PROMO_PRODUCT ?? ''

export const ourQueries = [
  'step',
  'purpose',
  'goal',
  'category',
  'productId',
  'title',
  'subtitle',
  'link',
  'isShowRequestForPermissions',
  'osType',
  'draftOrderId',
  'checkoutId',
  'status',
  'joinclub',
  'about',
  'email',
  'token',
  'order_id',
  'item_number',
  'draft_order_id',
  'orderId',
  'name',
  'gtm_debug',
  'button',
  'skus',
]

// Judge Me

export const judgeMePublicToken =
  process.env.NEXT_PUBLIC_JUDGE_ME_API_PUBLIC_TOKEN
export const judgeMeShopDomain = process.env.NEXT_PUBLIC_JUDGE_ME_SHOP_DOMAIN
export const judgeMePlatform = process.env.NEXT_PUBLIC_JUDGE_ME_PLATFORM
export const showJudgeMeInProduct =
  process.env.NEXT_PUBLIC_SHOW_JUDGE_ME_IN_PRODUCT === 'true'
export const showJudgeMeInVitalivClub =
  process.env.NEXT_PUBLIC_SHOW_JUDGE_ME_IN_VITALIV_CLUB === 'true'
export const showJudgeMeInAbout =
  process.env.NEXT_PUBLIC_SHOW_JUDGE_ME_IN_ABOUT === 'true'
