import classNames from 'classnames'
import Image from 'next/image'
import { useNotificationQueue } from '../../../customHooks/useNotifications'

import styles from './styles.module.scss'

export function NotificationsBox() {
  const queue = useNotificationQueue()
  const notification = useNotificationQueue()

  return (
    <>
      {Boolean(queue.entries) && (
        <div className={styles.root}>
          {queue.entries.map(({ id, data }: any) => (
            <div
              key={data.id}
              className={classNames({
                [styles.notify]: true,
              })}
            >
              <div className={styles.container}>
                <div
                  className={styles.closeBtn}
                  onClick={() => notification.remove(id)}
                >
                  <Image
                    src="/images/close-icon.svg"
                    layout="fill"
                    objectFit="cover"
                    alt="close"
                  />
                </div>

                <div className={styles.boxIcon}>
                  <div
                    style={{
                      minWidth: '48px',
                      minHeight: '48px',
                      position: 'relative',
                      marginRight: '24px',
                    }}
                  >
                    {data.type === 'info' ? (
                      <Image
                        src="/images/smileInfo.svg"
                        className={styles.image}
                        layout="fill"
                        objectFit="cover"
                        alt="smile"
                      />
                    ) : (
                      <Image
                        src="/images/smileError.svg"
                        className={styles.image}
                        alt="smile"
                        layout="fill"
                        objectFit="cover"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <p className={styles.text}>{data.text}</p>
                </div>
              </div>
              <div style={{ width: '103%' }}>
                <div
                  className={classNames({
                    [styles.loading]: true,
                    [styles.error]: data.type === 'error',
                    [styles.info]: data.type === 'info',
                  })}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
