// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const isDev = process.env.NODE_ENV === 'development'

Sentry.init({
  dsn: !isDev ? SENTRY_DSN : '',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment: isDev ? 'development' : 'production',
  beforeSend(event, hint) {
    const error = hint.originalException
    console.log(error, event, 'event')
    if (error) {
      if (
        error.response &&
        (error.response.status === 502 || error.response.status === 422)
      ) {
        return event
      } else if (error.errors) {
        return event
      }
      return null
    }
    return null
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
