import Head from 'next/head'
import { CookiesProvider } from 'react-cookie'
import { appWithTranslation } from 'next-i18next'
import '/app/styles/global/globalVariables.scss'
import '/app/styles/global/fonts.scss'
import '/app/styles/global/globalStyles.scss'
import 'swiper/scss'
import 'swiper/scss/pagination'
import 'nprogress/nprogress.css'
import { landingPageCookie, shopifySapCookie } from '../app/constants/constants'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { NotificationProvider } from '../app/customHooks/useNotifications'
import { NotificationsBox } from '../app/components/common/NotificationsBox'
import { getCookie, setCookies } from 'cookies-next'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import {
  filteredQueries,
  getUTMurlForTripleWhale,
  recordingMetricsForAnalytics,
} from '../app/utilities/savedAnalyticMetrics'

Router.events.on('routeChangeStart', (url) => {
  console.log(url)
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const [metrics, setMetrics] = useState({})

  const { query } = router

  let filterOurQuaries = filteredQueries(query)
  const [newQuery, setNewQuery] = useState(filterOurQuaries)

  useEffect(() => {
    if (
      JSON.stringify(newQuery) !== JSON.stringify(query) &&
      Object.entries(metrics).length
    ) {
      if (Object.keys(metrics)?.length) {
        router.push(
          { pathname: router.pathname, query: { ...query, ...metrics } },
          undefined,
          {
            shallow: true,
          }
        )

        setNewQuery({ ...query, ...metrics })
      }
    }
  }, [router.pathname, router.query])

  //
  recordingMetricsForAnalytics(query, metrics, newQuery, setMetrics)

  // data for analytics
  const landingPage = getCookie(landingPageCookie)

  if (!landingPage)
    setCookies(
      landingPageCookie,
      decodeURIComponent(
        router.asPath.includes('?')
          ? `/${router.asPath.substring(router.asPath.indexOf('?'))}`
          : '/'
      ),
      {
        maxAge: 3600 * 24 * 30,
      }
    )

  const shopifySap = getCookie(shopifySapCookie)
  if (!shopifySap) {
    setCookies(
      shopifySapCookie,
      decodeURIComponent(getUTMurlForTripleWhale(router.asPath)),
      {
        maxAge: 3600 * 3,
      }
    )
  } else {
    if (
      shopifySap !== getUTMurlForTripleWhale(router.asPath) &&
      getUTMurlForTripleWhale(router.asPath).length !== 0
    )
      setCookies(
        shopifySapCookie,
        decodeURIComponent(getUTMurlForTripleWhale(router.asPath)),
        {
          maxAge: 3600 * 3,
        }
      )
  }

  useEffect(() => {
    const divElement = document.querySelector('[role="presentation"]')
    if (pageProps.nonZendesk) {
      if (divElement) {
        divElement.style.display = 'none'
      }
    } else {
      if (divElement) {
        divElement.style.display = 'block'
      }
    }
  }, [pageProps.nonZendesk])

  return (
    <>
      <CookiesProvider>
        <NotificationProvider>
          <Head>
            {/* <meta
              name="google-site-verification"
              content="PbVLxj1-stF9l_5ipw3AY80xBWD3fwXi3ZxT1bx_UFE"
            /> */}
            <meta
              name="google-site-verification"
              content="334ehQRJGgmmt9GPHGFZxj4Jgfwu3UY7Abl8ZgOxKFg"
            />
            <meta name="robots" content="all" />
            <title>Welcome To Vitaliv Club</title>
          </Head>
          <Component {...pageProps} />

          <Script
            id="klaviyo"
            type="text/javascript"
            async
            strategy="lazyOnload"
            src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_ID}`}
          />
          {/* <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
          /> */}
          <Script
            id="gtm-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
            }}
          ></Script>

          <NotificationsBox />
        </NotificationProvider>
      </CookiesProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
