import { nanoid } from 'nanoid'
import { createNotificationContext } from 'react-notification-provider'
import { Notification } from '../types/Notification'

const { NotificationProvider, useNotificationQueue } =
  createNotificationContext<Notification>()

const EXPIRE_TIMEOUT = 14500

export function useNotifications() {
  const notification = useNotificationQueue()

  return {
    addError: (text: string) => {
      const id = nanoid(10)
      notification.add(id, {
        id,
        type: 'error',
        text,
      })

      setTimeout(() => {
        notification.remove(id)
      }, EXPIRE_TIMEOUT)
    },
    addInfo: (text: string) => {
      const id = nanoid(10)
      notification.add(id, {
        id,
        type: 'info',
        text,
      })

      setTimeout(() => {
        notification.remove(id)
      }, EXPIRE_TIMEOUT)
    },
  }
}

export { NotificationProvider, useNotificationQueue }
